import {
  Alert,
  Button,
  Layout,
  Row,
  Spin,
  Table,
  Tooltip,
  InputNumber
} from "antd";
import firebase from "firebase/app";
import "firebase/auth";
import React from "react";
import "./App.css";
import { main, trade } from "./balances.js";
import { isNumber } from "util";
import { IfFirebaseAuthedAnd } from "@react-firebase/auth";

const { Content, Footer } = Layout;
// const reactAppURL = "http://localhost:3300/public";
const reactAppURL = process.env.REACT_APP_HOMEPAGE;

let tradeColumns = [
  {
    title: "Pair",
    dataIndex: "currencyPair",
    key: "currencyPair",
    render: (value, record) => {
      return (
        <div style={{ color: record.type === "BUY" ? "#00c853" : "#d50000" }}>
          <b>{value.replace("_", "")}</b>
        </div>
      );
    }
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: value => +value.toFixed(3).toLocaleString("cs-CZ")
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: value =>
      (value > 100 ? Math.round(value) : +value.toFixed(3)).toLocaleString(
        "cs-CZ"
      )
  },
  {
    title: "Time",
    dataIndex: "createdTimestamp",
    key: "createdTimestamp",
    render: value => {
      const dateObject = new Date(value);
      return (
        <Tooltip title={dateObject.toLocaleDateString("cs-CZ")}>
          {dateObject.toLocaleTimeString("cs-CZ")}
        </Tooltip>
      );
    }
  }
];
let columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: value =>
      value.split(".")[0] + "." + value.split(".")[1].substr(1) + "."
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time"
  },
  {
    title: "Full balance",
    dataIndex: "fullBalance",
    key: "fullBalance",
    render: value => value
  },
  {
    title: "Profit",
    dataIndex: "profit",
    key: "profit",
    render: value => (
      <div style={{ color: value > 0 ? "#00c853" : "#d50000" }}>
        <b>{value.toLocaleString("cs-CZ")}</b>
      </div>
    )
  },
  {
    title: "Hodl",
    dataIndex: "hodl",
    key: "hodl",
    render: value => value.toLocaleString("cs-CZ")
  },
  {
    title: "Diff",
    dataIndex: "dif",
    key: "dif",
    render: value => <b>{value.toLocaleString("cs-CZ")}</b>
  }
];

class App extends React.Component {
  // constructor(props) {
  //   super(props);
  //   window.addEventListener("resize", this.handleWindowSizeChange);
  // }
  state = {
    currenciesToColumns: [],
    fullTableColumns: [],
    movements: [],
    dataSource: [],
    width: window.innerWidth,
    loading: true,
    error: null,
    lastTrade: [],
    dataSourceObject: {},
    dollars: process.env.REACT_APP_BITFINEX_BALANCE
  };

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.handleWindowSizeChange);
  // }

  componentDidMount() {
    this.firstTimeFetch();
  }

  firstTimeFetch = () => {
    fetch(`${reactAppURL}/getFile.php`)
      .then(r => r.json())
      .then(data => {
        this.setState(prevState => ({
          ...prevState,
          dataSourceObject: data,
          dollars:
            data.length > 0
              ? data[0].data["USD_CZK"].balance
              : process.env.REACT_APP_BITFINEX_BALANCE
        }));
      })
      .then(() => {
        this.createColumns();
        this.setState(prevState => ({
          ...prevState,
          dataSource: this.state.dataSourceObject,
          loading: false
        }));
        // main(this.addTemporaryData);
        trade(this.addLastTrade);
      });
  };

  // handleWindowSizeChange = () => {
  //   this.setState(prevState => ({ ...prevState, loading: true, error: null, width: window.innerWidth }));
  // };

  createColumns() {
    let movements = [];
    let currenciesToColumns = [];
    Object.keys(this.state.dataSourceObject[0].data).forEach(
      (currency, index) => {
        Object.keys(this.state.dataSourceObject[0].data[currency]).forEach(
          key => {
            key === "avb"
              ? movements.push({
                  title: currency,
                  key: currency,
                  render: x => {
                    let value =
                      (currency === "USD_CZK") |
                      (currency === "EUR_CZK") |
                      (currency === "CZK_CZK")
                        ? Math.round(+x.data[currency][key])
                        : x.data[currency][key];
                    return {
                      props: {
                        className: "customCell" + index // there it is!
                      },
                      children: (
                        <div
                          style={{ color: value > 0 ? "#000000" : "#373737" }}
                        >
                          {value > 0 ? +value : "-"}
                        </div>
                      )
                    };
                  }
                })
              : currenciesToColumns.push({
                  title: currency + " " + key,
                  key: currency + "_" + key,
                  width: 100,
                  render: x => {
                    let value = x.data[currency][key];
                    return {
                      props: {
                        className: "customCell" + index // there it is!
                      },
                      children: (value > 100
                        ? Math.round(value)
                        : +x.data[currency][key].toFixed(3)
                      ).toLocaleString("cs-CZ")
                    };
                  }
                });
          }
        );
      }
    );

    this.setState(prevState => ({
      ...prevState,
      currenciesToColumns: currenciesToColumns,
      movements: movements,
      fullTableColumns:
        this.state.width > 500
          ? this.getFullTableColumn(currenciesToColumns)
          : [],
      dataSource: this.state.dataSourceObject
    }));
  }

  getFullTableColumn(currenciesToColumns) {
    return [
      ...columns
        .filter(column => column.key === "date")
        .map(column => {
          return {
            ...column,
            fixed: "left",
            width: 110
          };
        }),
      ...currenciesToColumns,
      ...columns
        .filter(column => column.key !== "date" && column.key !== "time")
        .map(column => {
          return {
            ...column,
            fixed: "right",
            width: 100
          };
        })
    ];
  }

  addTemporaryData = (newBalance, error = null) => {
    if (!newBalance) {
      console.log("addTemporaryData", "Error probably in CoinMate API");
      error = "Error probably in CoinMate API";
    } else if (!!error) {
      console.log("addTemporaryData", error);
    } else {
      const newBalanceJSON = JSON.stringify(newBalance);
      let data = null;
      let xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${reactAppURL}/saveToFile.php?json=${newBalanceJSON}`,
        true
      );

      xhr.addEventListener("readystatechange", () => {
        if (this.readyState === 4) {
          // console.log(this.responseText);
          // don't really care for response
        }
      });
      xhr.send(data);
      this.setState(prevState => ({
        ...prevState,
        dataSource: [newBalance, ...this.state.dataSource]
      }));
    }
    this.setState(prevState => ({
      ...prevState,
      loading: false,
      error: error
    }));
  };

  addLastTrade = (lastTrade, error = null) => {
    this.setState(prevState => ({
      ...prevState,
      loading: false,
      error: error,
      lastTrade: error
        ? [...this.state.lastTrade]
        : [lastTrade, ...this.state.lastTrade]
    }));
  };

  onClose = () => {
    this.setState(prevState => ({
      ...prevState,
      error: null
    }));
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 500;

    if (this.state.dataSource.length === 0) {
      return (
        <Spin
          size="large"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            margin: "0 auto"
          }}
        />
      );
    }
    return (
      <IfFirebaseAuthedAnd
        filter={({ providerId, user }) => {
          return (
            user.email &&
            process.env.REACT_APP_ALLOW_USERS.indexOf(user.email) >= 0
          );
        }}
      >
        {({ isSignedIn, user, providerId }) => {
          return (
            <Layout>
              {this.state.error !== null ? (
                <Alert
                  message={JSON.stringify(this.state.error)}
                  type="error"
                  closable
                  onClose={this.onClose}
                  style={{ margin: "0 auto" }}
                />
              ) : (
                ""
              )}
              <div>
                <Row style={{ float: "right", padding: "12px" }}>
                  <InputNumber
                    min={0}
                    size={"large"}
                    defaultValue={this.state.dollars}
                    formatter={value =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={value => value.replace(/\$\s?|(,*)/g, "")}
                    onChange={value =>
                      this.setState(prevState => {
                        if (isNumber(value)) {
                          return { ...prevState, dollars: value };
                        }
                      })
                    }
                    disabled={this.state.loading}
                  />
                  {"   "}
                  <Button
                    type="primary"
                    size={"large"}
                    onClick={() => {
                      main(this.addTemporaryData, false, this.state.dollars);
                      this.setState(prevState => {
                        return { ...prevState, loading: true, error: null };
                      });
                    }}
                    loading={this.state.loading}
                  >
                    Update
                  </Button>
                  {"   "}
                  {!isMobile ? (
                    <React.Fragment>
                      <Button
                        type="primary"
                        size={"large"}
                        onClick={() => {
                          trade(this.addLastTrade);
                          this.setState(prevState => {
                            return { ...prevState, loading: true, error: null };
                          });
                        }}
                        loading={this.state.loading}
                      >
                        Last Trades
                      </Button>
                      {"   "}
                      <Button
                        type="primary"
                        size={"large"}
                        onClick={() => {
                          main(this.addTemporaryData, true, this.state.dollars);
                          this.setState(prevState => {
                            return { ...prevState, loading: true, error: null };
                          });
                        }}
                        loading={this.state.loading}
                      >
                        ↓ db
                      </Button>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  {"   "}
                  <Button
                    size={"large"}
                    onClick={() => {
                      firebase.auth().signOut();
                    }}
                  >
                    Log out
                  </Button>
                </Row>
              </div>
              <Content style={{ margin: "0 12px 0" }}>
                <div
                  style={{
                    padding: "12px",
                    background: "#fff",
                    minHeight: 360
                  }}
                >
                  Movement:
                  <Table
                    rowKey="movement"
                    key="movement"
                    dataSource={[this.state.dataSource[0]]}
                    columns={this.state.movements.map(column => {
                      return {
                        ...column,
                        width: `${200 / this.state.movements.length}%`,
                        title: (
                          <img
                            src={`assets/${column.title.replace(
                              "_CZK",
                              ""
                            )}.svg`}
                            alt={column.title.replace("_CZK", "")}
                          />
                        )
                      };
                    })}
                    pagination={{ hideOnSinglePage: true }}
                    scroll={{ y: 80 }}
                  />
                  Shorted table:
                  <Table
                    rowKey="simple"
                    key="simple"
                    dataSource={this.state.dataSource}
                    columns={columns
                      .filter(
                        column => column.key !== "dif" && column.key !== "hodl"
                      )
                      .map(column => {
                        return { ...column, width: "25%" };
                      })}
                    pagination={{ size: "small", pageSize: 10 }}
                    scroll={{ y: 400 }}
                  />
                  {!isMobile ? (
                    <div>
                      Full table:
                      <Table
                        rowKey="full"
                        key="full"
                        dataSource={this.state.dataSource}
                        columns={this.state.fullTableColumns}
                        pagination={{ size: "small", pageSize: 10 }}
                        scroll={{
                          y: 400,
                          x:
                            Object.keys(this.state.dataSource[0].data).length *
                              3 *
                              100 +
                            510 //every currency has 3 columns with width 100,  410 are fixed column - 110 + 3*100
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Button
                      type="primary"
                      size={"large"}
                      onClick={() => {
                        trade(this.addLastTrade);
                        this.setState(prevState => {
                          return { ...prevState, loading: true, error: null };
                        });
                      }}
                      style={{ float: "right", margin: "0 12px" }}
                      loading={this.state.loading}
                    >
                      Last Trades
                    </Button>
                  </Row>
                  <div>
                    Last Trades:
                    <Table
                      rowKey="lastTrade"
                      key="lastTrade"
                      dataSource={this.state.lastTrade[0]}
                      columns={tradeColumns}
                      pagination={{ size: "small", pageSize: 10 }}
                      scroll={{
                        y: 300
                      }}
                    />
                  </div>
                </div>
              </Content>
              <Footer style={{ textAlign: "center" }}>znoj.cz</Footer>
            </Layout>
          );
        }}
      </IfFirebaseAuthedAnd>
    );
  }
}

export default App;
