import { saveAs } from "file-saver";
let request = require("request");
let crypto = require("crypto");
require("dotenv").config();

export const main = (payload, save = false, dollars) => {
  let errorOccured = null;
  const nonce = Date.now().toString();
  // ------------------------  Bitfinex ----------------------------
  const bitFinex =
    Number(dollars) > 0
      ? Number(dollars)
      : Number(process.env.REACT_APP_BITFINEX_BALANCE);

  // -------------------------- Coinmate --------------------------
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const publicKeyCM = process.env.REACT_APP_PUBLIC_KEY_CM;
  const privateKeyCM = process.env.REACT_APP_PRIVATE_KEY_CM;
  const message = nonce.toString() + clientId.toString() + publicKeyCM;

  const margin = Number(process.env.REACT_APP_MARGIN);
  const originCZK = Number(process.env.REACT_APP_ORIGIN_CZK);
  const originBTC = Number(process.env.REACT_APP_ORIGIN_BTC);
  const reactAppURL = process.env.REACT_APP_HOMEPAGE;

  const signature = crypto
    .createHmac("sha256", privateKeyCM)
    .update(message)
    .digest("hex")
    .toUpperCase();

  let balance = {
    BTC_CZK: {},
    LTC_CZK: {},
    ETH_CZK: {},
    XRP_CZK: {},
    DASH_CZK: {},
    BCH_CZK: {},
    CZK_CZK: { rate: 1 },
    EUR_CZK: {},
    USD_CZK: { balance: bitFinex, avb: 0 }
  };

  const allCalls = Object.keys(balance).length - 2; //exchangeratesapi, balances,

  let calls = 0;

  const recount = () => {
    if (calls < allCalls) {
      calls += 1;
      return;
    }

    if (errorOccured !== null) {
      payload(null, errorOccured);
      return;
    }
    let fullBalance = 0;

    Object.keys(balance).forEach(pair => {
      balance[pair].result = Math.round(
        balance[pair].rate * balance[pair].balance
      );
      fullBalance += balance[pair].result;
    });

    fullBalance -= margin;
    let hodl = Math.round(balance.BTC_CZK.rate * originBTC);
    let dif = fullBalance - hodl;
    let profit = fullBalance - originCZK;
    let date = new Date().toLocaleDateString("cs-CZ");
    let time = new Date().toLocaleTimeString("cs-CZ");

    // console.log(`date: ${date}`);
    // console.log(`time: ${time}`);
    // console.log(`full balance: ${fullBalance}`);
    // console.log(`hodl: ${hodl}`);
    // console.log(`dif: ${dif}`);
    // console.log(`PROFIT: ${profit}`);

    balance = {
      date: date,
      time: time,
      data: balance,
      fullBalance: fullBalance,
      hodl: hodl,
      dif: dif,
      profit: profit
    };

    var data = null;
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function() {
      if (this.readyState === 4) {
        let existingData = JSON.parse(this.responseText);
        try {
          existingData = JSON.parse(this.responseText)
            .filter(record => record.date !== balance.date)
            .reverse();
        } catch (e) {
          console.log("readingFileError", e);
          existingData = [];
        }
        // only one record today - the last one
        existingData.push(balance);
        payload(balance);

        // SAVE file
        if (save) {
          var file = new File(
            [JSON.stringify(existingData).toString()],
            `data2.json`,
            {
              type: "text/plain;charset=utf-8"
            }
          );
          saveAs(file);
        }
      }
    });

    xhr.open("GET", `${reactAppURL}/getFile.php`);
    xhr.send(data);
  };

  request(
    {
      method: "POST",
      url: "https://coinmate.io/api/balances",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: `clientId=${clientId}&publicKey=${publicKeyCM}&nonce=${nonce}&signature=${signature}`
    },
    function(error, response, body) {
      if (error || !JSON.parse(body).data) {
        console.log(`CoinMate balances`, error);
        errorOccured = {
          title: `https://coinmate.io/api/balances`,
          info: error + JSON.stringify(body),
          more: errorOccured ? { ...errorOccured } : null
        };
      } else {
        // console.log("Status:", response.statusCode);
        // console.log("error", error);
        // console.log("Headers:", JSON.stringify(response.headers));
        // console.log("Response:", body);
        // console.log("------------\n");
        let data = JSON.parse(body).data;
        // console.log(data);
        Object.keys(data).forEach(currency => {
          if (balance[currency + "_CZK"]) {
            balance[currency + "_CZK"].balance = data[currency].balance;
            balance[currency + "_CZK"].avb = data[currency].available
              .toFixed(3)
              .toLocaleString("cs-CZ");
            if (currency === "BTC") {
              balance["BTC_CZK"].balance += 0.1;
            }
          }
        });
        // console.log(balance);
      }
      recount();
    }
  );

  Object.keys(balance)
    .filter(
      pair => pair !== "EUR_CZK" && pair !== "CZK_CZK" && pair !== "USD_CZK"
    )
    .forEach(pair =>
      request(`https://coinmate.io/api/ticker?currencyPair=${pair}`, function(
        error,
        response,
        body
      ) {
        if (error) {
          console.log(`CoinMate pair: ${pair}`, error);
          errorOccured = {
            title: `https://coinmate.io/api/ticker?currencyPair=${pair}`,
            info: error,
            more: errorOccured ? { ...errorOccured } : null
          };
        } else {
          balance[pair].rate = JSON.parse(body).data.last;
          //   balance[pair].result = balance[pair].rate * balance[pair].balance;
        }

        recount();
      })
    );

  request(
    {
      jar: true,
      url: `https://api.exchangeratesapi.io/latest?symbols=USD,CZK`
    },
    function(error, response, body) {
      if (error) {
        console.log(`exchangeratesapi`, error);
        errorOccured = {
          title: `https://api.exchangeratesapi.io/latest?symbols=USD,CZK`,
          info: error,
          more: errorOccured ? { ...errorOccured } : null
        };
      }
      balance["EUR_CZK"].rate = JSON.parse(body).rates.CZK;
      balance["USD_CZK"].rate =
        JSON.parse(body).rates.CZK / JSON.parse(body).rates.USD;
      recount();
    }
  );
};

export const trade = payload => {
  let errorOccured = null;
  const nonce = Date.now().toString();

  // -------------------------- Coinmate --------------------------
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const publicKeyCM = process.env.REACT_APP_PUBLIC_KEY_CM;
  const privateKeyCM = process.env.REACT_APP_PRIVATE_KEY_CM;
  const message = nonce.toString() + clientId.toString() + publicKeyCM;

  const signature = crypto
    .createHmac("sha256", privateKeyCM)
    .update(message)
    .digest("hex")
    .toUpperCase();

  request(
    {
      method: "POST",
      url: "https://coinmate.io/api/tradeHistory",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: `clientId=${clientId}&nonce=${nonce}&signature=${signature}`
    },
    function(error, response, body) {
      if (error) {
        console.log(`CoinMate balances`, error);
        errorOccured = {
          title: "https://coinmate.io/api/tradeHistory",
          info: error,
          more: errorOccured ? { ...errorOccured } : null
        };
        payload(null, errorOccured);
      } else {
        // console.log("Status:", response.statusCode);
        // console.log("Headers:", JSON.stringify(response.headers));
        // console.log("Response:", body);
        // console.log("------------\n");
        let data = JSON.parse(body).data;
        // console.log("trade", data);
        payload(data);
      }
    }
  );
};
