import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthConsumer,
  FirebaseAuthProvider
} from "@react-firebase/auth";
import App from "./App";
import { Button, Layout, Result } from "antd";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Login extends React.Component {
  render() {
    return (
      <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
        <FirebaseAuthConsumer>
          {({ isSignedIn, user, providerId }) => {
            if (isSignedIn) {
              if (
                this.props.logout ||
                !user.email ||
                process.env.REACT_APP_ALLOW_USERS.indexOf(user.email) < 0
              ) {
                return (
                  <Result
                    status="error"
                    title="This page is not for you"
                    extra={[
                      <Button
                        type="primary"
                        size={"large"}
                        key={"logout"}
                        onClick={() => {
                          firebase.auth().signOut();
                        }}
                      >
                        Logout
                      </Button>
                    ]}
                  ></Result>
                );
              } else {
                return <App />;
              }
            } else {
              return (
                <Layout style={{ height: "100vh" }}>
                  <Button
                    type="primary"
                    size={"large"}
                    style={{ margin: "auto" }}
                    onClick={() => {
                      const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                      firebase.auth().signInWithPopup(googleAuthProvider);
                    }}
                  >
                    Log in
                  </Button>
                </Layout>
              );
            }
          }}
        </FirebaseAuthConsumer>
      </FirebaseAuthProvider>
    );
  }
}

export default Login;
